import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import { HeightSpacer } from "../../../../shared/Spacer/styles";
import {
  ModalContainer,
  Text,
  DialogStyled,
  TextContainer,
  ButtonContainer,
  SolidPurpleButton,
  TitleContainer,
  CloseIconStyled,
  ContactUsContainer,
} from "./styles";
import Lottie from "react-lottie";
import rejectedResume from "../../../../../assets/lotties/resume/rejected.json";
import {
  updateRejectedResumePopupStatus,
  updateUserProfileDrawerIndex,
} from "../../../../../redux/actions/user_action";
import { resizeWindow } from "../../../../../redux/actions/screen_action";
import { sendTrackingEvent } from "../../../../../helpers/tracking_management";

function RejectedResumePopup(props) {
  const router = useRouter();
  const dispatch = useDispatch();

  const isRejectedResumePopupOpen = useSelector(
    (state) => state.user.isRejectedResumeOpen
  );
  const screenWidth = useSelector((state) => state.screen.screenWidth);

  const onClosingPopup = () => {
    dispatch(updateRejectedResumePopupStatus(false));
  };

  const onUpdateResumeClicked = () => {
    sendTrackingEvent({
      event: "CE_update-resume-reject-resume-pop-up",
    });
    dispatch(updateUserProfileDrawerIndex(2));
    router.push("/profile#my-resume", undefined, { shallow: true });
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      dispatch(resizeWindow(window.innerWidth));
    });

    return () => {
      window.removeEventListener("resize", () => {
        dispatch(resizeWindow(null));
      });
    };
  }, []);

  return (
    <DialogStyled open={isRejectedResumePopupOpen} onClose={onClosingPopup}>
      <ModalContainer>
        <TitleContainer>
          <Text $text={"title"}>RESUME REJECTED</Text>
          <CloseIconStyled onClick={onClosingPopup} />
        </TitleContainer>
        <HeightSpacer $height={"16px"} />
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: rejectedResume,
          }}
          width={180}
        />
        <TextContainer>
          <Text $text={"subtitle"}>Oh no, your resume has been rejected.</Text>
          <HeightSpacer $height={"16px"} />
          <Text $text={"message"}>
            Kindly update your resume to proceed with job application.
          </Text>
        </TextContainer>

        <HeightSpacer $height={screenWidth < 768 ? "46px" : "24px"} />

        <ContactUsContainer>
          <Text $text={"resumeIssue"}>
            <b>Having issue on your resume?</b>
          </Text>
          <br />
          <HeightSpacer $height={"20px"} />
          <a href={"mailto:hello@hiredly.com"}>
            <Text $text="underlinedContact">Reach out</Text>
          </a>{" "}
          <Text $text="askHelp">
            to our friendly customer service for help!
          </Text>
        </ContactUsContainer>
        <HeightSpacer $height={"50px"} />
        <ButtonContainer>
          <SolidPurpleButton onClick={onUpdateResumeClicked}>
            Update Resume
          </SolidPurpleButton>
        </ButtonContainer>
      </ModalContainer>
    </DialogStyled>
  );
}

export default RejectedResumePopup;
