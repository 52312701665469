import * as types from "../types/screen_type";

const resizeWindow = (width) => async (dispatch) => {
  return dispatch({
    type: types.RESIZE_WINDOW,
    screenWidth: width,
  });
};

export { resizeWindow };
